import { createApp } from 'vue'
import { createHead } from '@unhead/vue'
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'
import { library } from '@fortawesome/fontawesome-svg-core'
import { createPinia } from 'pinia'
import { Capacitor } from '@capacitor/core'
import { createI18n } from 'vue-i18n'
import OneSignal from 'onesignal-cordova-plugin'
import { piniaCapacitorPersist } from 'pinia-plugin-capacitor-persist'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faXTwitter, faLine, faLinkedin, faGoogle, faApple, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faCalculator, faDiamondTurnRight, faCompass, faCheck, faCircleUser, faBookmark as faBookmarkSolid, faPlus, faMinus, faLocationArrow, faSquare, faSquareBolt } from '@fortawesome/pro-solid-svg-icons'
import { faBed, faUtensils, faCircleExclamation, faCar, faLocationCheck, faStar, faBriefcase, faHouse,  faMagnifyingGlass, faMagnifyingGlassLocation, faLocationCrosshairs, faLink, faEnvelope, faPaperPlaneTop, faShareNodes, faCoins, faCircle1, faCircle2, faCircle3, faRotateRight, faRadar, faTrash, faArrowDown, faArrowUpArrowDown, faTriangleExclamation, faBookmark as faBookmarkRegular, faMapLocationDot, faArrowRight, faWifiSlash, faRobotAstromech, faPlugCircleMinus, faPlugCircleBolt, faCircleCheck, faCarCircleBolt, faGear, faEye, faEyeSlash, faAngleDown, faAngleUp, faCircleInfo, faCircleXmark, faClockRotateLeft, faCircleLocationArrow, faCirclePlus, faCircleMinus, faFilter, faRoute, faBolt, faLocationDot, faClock, faSpinnerThird, faBatteryExclamation, faBatteryEmpty, faBatteryLow, faBatteryQuarter, faBatteryHalf, faBatteryThreeQuarters, faBatteryFull, faRoad, faChargingStation, faArrowLeft, faCarSideBolt, faSlidersSimple, faUser, faChevronUp, faChevronDown,  faCalendarCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { init, replayIntegration, browserTracingIntegration } from '@sentry/vue'
import FloatingVue from 'floating-vue'

import mitt from 'mitt'
import App from '@/App.vue'
import router from '@/router'
import en from './locales/en.json'
import th from './locales/th.json'
import '@/index.css'
import 'floating-vue/dist/style.css'

library.add(faSquareBolt, faSquare, faUser, faBed, faUtensils, faCircleExclamation, faCar, faLocationCheck, faBriefcase, faHouse, faMagnifyingGlass, faMagnifyingGlassLocation, faMinus, faLocationCrosshairs, faLink, faCalculator, faEnvelope, faXTwitter, faLine, faLinkedin, faFacebook, faPaperPlaneTop, faShareNodes, faCoins, faDiamondTurnRight, faCompass, faCircle1, faCircle2, faCircle3, faStar, faCircleCheck, faCheck, faAngleDown, faAngleUp, faRotateRight, faRadar, faTrash, faArrowDown, faArrowUpArrowDown, faTriangleExclamation, faBookmarkSolid, faBookmarkRegular, faMapLocationDot, faCircleUser, faArrowRight, faApple, faWifiSlash, faRobotAstromech, faPlugCircleMinus, faPlugCircleBolt, faCircleCheck, faPlus, faCarCircleBolt, faGear, faEye, faEyeSlash, faAngleDown, faAngleUp, faCircleInfo, faGoogle, faCircleXmark, faClockRotateLeft, faLocationArrow, faCircleLocationArrow, faCirclePlus, faCircleMinus, faFilter, faRoute, faBolt, faLocationDot, faClock, faSpinnerThird, faBatteryExclamation, faBatteryEmpty, faBatteryLow, faBatteryQuarter, faBatteryHalf, faBatteryThreeQuarters, faBatteryFull, faRoad, faChargingStation, faArrowLeft, faCarSideBolt, faSlidersSimple, faChevronUp, faChevronDown, faCalendarCircleExclamation)
const pinia = createPinia()
const app = createApp(App)
const head = createHead()
const emitter = mitt()

const i18n = createI18n({
  locale: 'th',
  fallbackLocale: 'en',
  legacy: false,
  globalInjection: true,
  messages: {
    en,
    th
  }
})

pinia.use(piniaCapacitorPersist)
app.use(head)
app.use(pinia)
app.use(i18n)
app.use(FloatingVue)
app.config.globalProperties.emitter = emitter

if(import.meta.env.VITE_ONESIGNAL_ID) {
  if (!Capacitor.isNativePlatform()) {
    app.use(OneSignalVuePlugin, {
      appId: import.meta.env.VITE_ONESIGNAL_ID,
      allowLocalhostAsSecureOrigin: import.meta.env.MODE === 'development'
    })
  } else {
    document.addEventListener('deviceready', () => {
      OneSignal.initialize(import.meta.env.VITE_ONESIGNAL_ID)
      OneSignal.Notifications.addEventListener('click', function (opened) {
        const notificationData = JSON.stringify(opened)
        console.log('notificationOpenedCallback: ' + notificationData)
      })
    }, false)
  }
}

if (import.meta.env.MODE !== 'development') {
  init({
    app,
    dsn: 'https://3b25ad23dba763fc7a555a2003104792@o4505798483771392.ingest.sentry.io/4505798508609536',
    integrations: [
      browserTracingIntegration({ router }),
      replayIntegration()
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: ['localhost', /^https:\/\/beta\.saifah\.app/, /^https:\/\/saifah\.app/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
    trackComponents: true
  })
}

app.use(router)

app.component('FontAwesomeIcon', FontAwesomeIcon)
app.mount('#app')
